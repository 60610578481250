import { http } from '@/http/axios.js'
import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js'
import { Message } from 'element-ui'
import { dateFormat } from '@/utils/index.js'
// 设备监管上锁
export function submitLockAPI(data) {
  return http({
    url: '/admin/order/device/lock',
    method: 'post',
    data
  })
}
// 列表
export function getFinanceOrderListAPI(params) {
  return http({
    url: '/admin/finance/order/page',
    method: 'get',
    params
  })
}
// 减免金额
export function handleOrderBillOffAmountAPI(data) {
  return http({
    url: '/admin/order/bill/offAmount',
    method: 'post',
    data
  })
}
// 线下支付
export function handleOrderBillOffLinePayAPI(data) {
  return http({
    url: '/admin/finance/orderBill/offline/pay',
    method: 'post',
    data
  })
}
// 计算线下支结果
export function getOfflineResultAPI(data) {
  return http({
    url: '/admin/finance/orderBill/offline/calc',
    method: 'post',
    data
  })
}
// 调整账单日
export function updateBillDateAPI(data) {
  return http({
    url: '/admin/finance/orderBill/date/modify',
    method: 'post',
    data
  })
}
// 调整账单金额
export function updateBillAmountAPI(data) {
  return http({
    url: '/admin/finance/orderBill/amount/modify',
    method: 'post',
    data
  })
}
// 订单账单列表
export function getOrderBillListAPI(orderNoList) {
  return http({
    url: '/admin/finance/orderBill/unpay/list',
    method: 'get',
    params: { orderNoList }
  })
}
// 导出
export function exportAPI(data) {
  showScreenLoading()
  axios({
    method: 'post',
    url: '/api/admin/finance/bill/export',
    responseType: 'blob',
    data,
    headers: {
      Authorization: getToken() || ''
    }
  })
    .then(async res => {
      console.log(res)
      hideScreenLoading()
      let data = res.data
      // if (!data || data.type !== 'application/vnd.ms-excel') {
      //   let response = await new Response(data).text()
      //   response = JSON.parse(response)
      //   Message.error(response?.message || '导出失败')
      //   return
      // }
      let url = window.URL.createObjectURL(new Blob([data]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      // let filename = res?.headers['content-disposition'].split('filename=').pop() || '财务明细.xlsx'
      let filename = `财务账单${dateFormat()}.xlsx`
      a.setAttribute('download', decodeURIComponent(filename))
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a) //释放标签
      console.log(222)
    })
    .catch(error => {
      hideScreenLoading()
      console.log(error)
    })
}
